import React from 'react';

const ComingSoon = () => {
	return (
		<div
			className='raised_card about_me container'
			style={{ backgroundColor: '#1D3A41' }}>
			<div style={{ height: '27vh' }}>
				<h1>Hi there, I am adding a new project here pretty soon.</h1>
			</div>
		</div>
	);
};

export default ComingSoon;
