import { Fragment } from 'react';

const GithubLogo = () => {
  return (
    <Fragment>
      <a
        href='https://github.com/utviklerToni'
        target={'_blank'}
        rel={'noreferrer'}
      >
        <div style={{ display: 'flex' }}>
          <svg
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            height='16px'
            width='16px'
          >
            <title>GitHub</title>
            <path d='M8 0C4.854-.044 1.82 1.978.629 4.886c-1.289 2.952-.542 6.639 1.806 8.85A7.99 7.99 0 0 0 6 15.734v-2.73c-.625-.034-1.362.155-1.834-.385-.586-.56-.9-1.53-1.828-1.602-.65-.21-.338-1.444.842-.842.857.33 1.147 1.292 1.82 1.825h1c-.03-1.07.93-2.03 2-2-.917-.03-1.846.076-2.751-.094-1.544-.352-2.57-2.072-2.165-3.592a12.227 12.227 0 0 1 1.55-3.255c.356-.157.653.23.972.353.38.188.744.435 1.132.588.874-.015 1.763.029 2.628-.022.602-.312 1.18-.679 1.787-.976.39.046.457.534.672.805.506.905.998 1.873 1.161 2.91.22 1.713-1.305 3.364-3.03 3.283H8c1.163-.038 2.135 1.082 2 2.224v3.516c3.22-.782 5.735-3.757 5.971-7.062.302-3.065-1.371-6.195-4.073-7.666A7.973 7.973 0 0 0 8 0z'></path>
          </svg>
          <p style={{ marginLeft: '9px', color: '#ff7b00' }}>github Profile</p>
        </div>
      </a>
    </Fragment>
  );
};

export default GithubLogo;
